<template>
  <v-content class="cbg">
    <v-container
      fill-height
      fluid
    >
      <v-layout
        align-center
        justify-center>
        <v-flex
          xs12
          sm6
          md4
          lg3
          
        >
          <v-card
            style="border-radius: 15px"
            class="elevation-8 py-8"
          >
          <v-row
            justify="center"
          >
            <div class="text-center">
              <v-img
                      src="@/assets/imgs/akoko_market_logo.png"
                      class="white--text align-center mx-auto"
                      height="55px"
                      width="180px"
              />
              <span class="grey--text caption text--darken-1 mr-0"><v-icon small>mdi-copyright</v-icon>Enterprise Edition</span>
            </div>
            <v-row justify="center" align="center" class="col-11 mt-12">
              <v-card-text>
<!--                <p class="display-2 text&#45;&#45;primary mb-3">Admin</p>-->
                <v-form>
                  <v-text-field
                          ref="phone"
                          v-model="phone"
                          :rules="[() => !!phone || 'This field is required']"
                          filled
                          prepend-inner-icon="mdi-phone"
                          placeholder="Phone"
                          required

                  />
                  <v-text-field
                          ref="password"
                          v-model="password"
                          :rules="[() => !!password || 'This field is required']"
                          :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                          :type="showPassword ? 'text' : 'password'"
                          filled
                          prepend-inner-icon="mdi-lock"
                          placeholder="Password"
                          counter
                          required
                          @keydown.enter="login"
                          @click:append="showPassword = !showPassword"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions class="col-9">
                <v-btn
                    :loading="loader"
                    align-center
                    justify-center
                    color="primary"
                    dark
                    large
                    block
                    rounded
                    @click="login"><v-icon
                    left
                    >mdi-lock-open-variant</v-icon> Phone
                </v-btn>
              </v-card-actions>
              <div class="col-12 mt-3">
                <v-btn
                  text
                  color="secondary"
                  rounded
                  @click="$router.push({name: 'home'})"
                >
                  <v-icon>mdi-arrow-left</v-icon> Back
                </v-btn >
              </div>
            </v-row>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
export default {
  data: function () {
    return {
      phone: '',
      password: '',
      showPassword: false,
      loader: false
    }
  },

  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    login: function () {
      this.loader = true
      setTimeout(_=>{
        this.$router.push({name: 'admin-dashboard'})
      }, 3000)
    }
  },
  metaInfo () {
    return {
      title: 'Akokomarket | Login'
    }
  }
}
</script>

<style scoped lang="scss">
  .cbg{
    border:1px solid #e3e3e3;
    background: #e3e3e3;
    height: 100vh;
    /*background-image:url('../assets/img/akoko1.jpg');*/
  }
</style>
